import React, {useEffect, useState} from 'react';
import './about.scss';
import Photo from 'assets/placeholders/about-picture.jpg';
import { Player } from '@lottiefiles/react-lottie-player';
import WorldLottie from 'assets/lotties/world.json';

const About = () => {
    const UtcTimeZoneDiff = 7; //Thaïlande
    var [date,setDate] = useState(calcTime(UtcTimeZoneDiff));
    function calcTime(offset) {
        // create Date object for current location
        var d = new Date();
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        var nd = new Date(utc + (3600000*offset));

        // return time as a string
        return nd.toLocaleString([], { hour: "2-digit", minute: "2-digit" });
    }

    useEffect(() => {
        var timer = setInterval(()=>setDate(calcTime(UtcTimeZoneDiff)), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    })
    return (
        <div className="sub-section about-intro">
            <span id="about" className="anchor"></span>
            <div data-lag="2" className="profile-picture">
                <div className="image-wrapper">
                    <img className="picture-contact-me img-vertical-parallax" src={Photo} alt=""/>
                    <div className="pills location">
                        <Player
                            className="world-lottie"
                            src={WorldLottie}
                        >
                        </Player>
                        <span className="pills-text">Thaïlande <span id="local-time-zone">{date}</span></span>
                    </div>
                </div>
            </div>
            <div className="about-intro__text">
                <h2>
                    <span>Designer <em className="editorial">freelance</em></span>
                    <span>travaillant depuis</span>
                    <span>le monde entier</span>
                </h2>
                <div className="about-intro__presentation">
                    <p>
                        Designer graphique full-remote fort de plus de 8 années d’expérience au sein d'agences 360 à
                        Paris,
                        au service d’entreprises leaders de leur secteur et d’ambitieuses startups.
                    </p>
                    <p>
                        Créateur de marques captivantes et de produits performants, puisant l'inspiration à travers
                        le monde pour une créativité sans frontières.
                        <a href="#contact" className="link">
                            <span className="link-underline">Télé-travaillons ensemble.</span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default About;