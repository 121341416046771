import React, { useEffect } from "react";
import {CursorFollow, modifyCursor} from "utils/cursorClass";

const Cursor = () => {
    useEffect(() => {
        const cursor = new CursorFollow('cursor');
        cursor.init();
        if (document.getElementById("projects-hover-reveal")) {
            const imageRevealListElt = new CursorFollow('projets-hover-reveal', 0.05);
            imageRevealListElt.init();
        }

        modifyCursor('.link', 'cursor-on-link');
        modifyCursor('.no-cursor', 'cursor-hidden');
// modifyCursor('.picture-contact-me', 'cursor-contact');
        modifyCursor('.projets-list__item.active', 'cursor-project');
        return () => {
            // This is the cleanup function
            // It will be called when the component is unmounted
            cursor.cancelAnimation();
        };
    }, []);
    return(
        <div id="cursor">
            <div className="cursor__circle"></div>
        </div>
    );
};

export default Cursor;
