import React from 'react';
import {ParallaxBanner, ParallaxBannerLayer} from "react-scroll-parallax";

const BannerImage = ({desktopImg, mobileImg, altLabel}) => {
    const parallaxSpeed = -8;
    return (
        <div className="banner">
        <ParallaxBanner className="banner">
            <ParallaxBannerLayer speed={parallaxSpeed}>
                <picture>
                    <source srcSet={desktopImg} media="(min-width: 577px)" />
                    <img src={mobileImg} alt={altLabel} />
                </picture>
            </ParallaxBannerLayer>
        </ParallaxBanner>
        </div>
    );
};

export default BannerImage;