import React, {useState} from 'react';
import {Player} from "@lottiefiles/react-lottie-player";

const SpecialityCard = ({speciality, openModal}) => {
    const [lottie, setLottie] = useState(null);
    return (
        <div onMouseEnter={() => lottie.play()} className="speciality-description__service">
            <Player
                lottieRef={instance => {
                    setLottie(instance);
                }}
                className="speciality-lottie"
                src={speciality.lottie.url}
            >
            </Player>
            <h4>{speciality.title}</h4>
            <p>{speciality.description}</p>
            <span onClick={openModal} className="link"><span className="link-underline">En savoir plus</span></span>
        </div>
    );
};

export default SpecialityCard;