import React from 'react';
import './heroSection.scss';
import Title1 from 'assets/svg/rounded_text_1.svg';
import Title2 from 'assets/svg/rounded_text_2.svg';

const HeroSection = () => {
    return (
        <section className="hero">
            <div className="title">
                <div id="title-container1">
                    <img src={Title1} id="rounded-title-1" alt=""/>
                </div>
                <div id="title-container2">
                    <img src={Title2} id="rounded-title-2" alt=""/>
                </div>
            </div>
            <div className="availability">
                <div className="pills availability-pill">
                    <span className="flashing-dot"></span>
                    <span className="pills-text">Disponible</span>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;