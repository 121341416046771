import React, {useEffect, useState} from 'react';
import CloseIcon from 'assets/icons/close.svg';
import ArrowRight from 'assets/icons/arrow_right.svg';
import ArrowLeft from 'assets/icons/arrow_left.svg';
import './modalSpeciality.scss';
import ServicesData from "data/services.json";

const ModalSpeciality = ({id, onClose}) => {
    const [currentServiceId, setCurrentServiceId] = useState(id)
    const [serviceInfo, setServiceInfo] = useState(ServicesData[currentServiceId]);

    const previousService = () => {
        if(currentServiceId > 0) {
            setCurrentServiceId(currentServiceId - 1);
            setServiceInfo(ServicesData[currentServiceId - 1]);
        }
    }
    const nextService = () => {
        if(currentServiceId < 3) {
            setCurrentServiceId(currentServiceId + 1);
            setServiceInfo(ServicesData[currentServiceId + 1]);
        }
    }
    useEffect(() => {
        const elt = document.querySelector(".modal-speciality");
        if(elt) {
            elt.scrollIntoView();
        }
    }, [serviceInfo]);
    return (
        <div className="modal-wrapper">
            <div className="modal-speciality">
                <div className="modal-content-wrapper">
                    <div className="modal-header">
                        <div className="heading">
                            <h1>{serviceInfo.title}</h1>
                            <button onClick={onClose} className="modal-close-button"><img src={CloseIcon} alt="Fermer"/></button>
                        </div>
                        <p>{serviceInfo.description}</p>
                    </div>
                    <div className="modal-text-content">
                        <div className="content-inner">
                            <div className="speciality-description">
                                <h3>Mon expertise</h3>
                                {serviceInfo.expertise.map((text, index) => <p key={index}>{text}</p>)}
                            </div>
                            {serviceInfo.products &&
                                <div className="speciality-products">
                                    <h3>Produits</h3>
                                    <div className="speciality-products__list">
                                        {serviceInfo.products.map((product, index) => <span
                                            key={index}>{product}</span>)}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="speciality-key-points">
                            <h3>Étapes clés</h3>
                            <ul className="speciality-key-points__list">
                                {serviceInfo.process.map((step, index) =>
                                    <li key={index}>{step}</li>
                                )}
                            </ul>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <div className="btn-wrapper">
                            <button className="btn" onClick={previousService} disabled={currentServiceId===0}>
                                <img src={ArrowLeft} alt="Précédent"/></button>
                            <button className="btn" onClick={nextService} disabled={currentServiceId===3}>
                                <img src={ArrowRight} alt="Suivant"/>
                            </button>
                        </div>
                        <a href="#contact" className="contact-link">Parlons de votre projet</a>
                    </div>
                </div>
                <div className="modal-spaciality__image">
                    <img src={serviceInfo.image.url} alt={serviceInfo.image.altLabel}/>
                </div>
            </div>
        </div>
    );
};

export default ModalSpeciality;