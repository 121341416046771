import React from 'react';

const Image = ({srcSet, src, alt}) => {
    return (
        <picture>
            <source srcSet={srcSet} media="(min-width: 577px)" />
            <img src={src} alt={alt} />
        </picture>
    );
};

export default Image;