import React from 'react';
import ImageWithCaption from "../ImageWithCaption";
import Image from "../../shared/Image";
// Images for desktop
import CxmpMoodboard from "assets/images/comexposium/desktop/cxmp_moodboard.webp";
import GraphicBoard from "assets/images/comexposium/desktop/cxmp_graphicboard.webp";
import LogoBanner from "assets/images/comexposium/desktop/banner_cxmp_logo.webp";
import PosterBanner from "assets/images/comexposium/desktop/banner_cxmp_poster.webp";
import LogoHorizontal from "assets/images/comexposium/desktop/cxmp_logo_horizontal.webp";
import LogoMockupIphone from "assets/images/comexposium/desktop/cxmp_logo_mockup_iphone.webp";
import LogoSafeZone from "assets/images/comexposium/desktop/cxmp_logo_safe_zone.webp";
import LogoApp from "assets/images/comexposium/desktop/cxmp_logo_app.webp";
import LogoAppMockup from "assets/images/comexposium/desktop/cxmp_logo_app_mockup.webp";
import MockupPoster from "assets/images/comexposium/desktop/cxmp_mockup_poster.webp";
import ImageSlices from "assets/images/comexposium/desktop/cxmp_image_slices.webp";
import MockupPlanning from "assets/images/comexposium/desktop/cxmp_mockup_planning.webp";
import MockupLeaflet from "assets/images/comexposium/desktop/cxmp_mockup_leaflet.webp";
import MockupGuides from "assets/images/comexposium/desktop/cxmp_mockup_guides.webp";
import FontPoppins from "assets/images/comexposium/desktop/cxmp_font_poppins.webp";
import Illustrations from "assets/images/comexposium/desktop/cxmp_illustrations.webp";
import PosterFashion from "assets/images/comexposium/desktop/cxmp_poster/cxmp_poster_fashion.webp";
import PosterFood from "assets/images/comexposium/desktop/cxmp_poster/cxmp_poster_food.webp";
import PosterSport from "assets/images/comexposium/desktop/cxmp_poster/cxmp_poster_sport.webp";
import Pictograms from "assets/images/comexposium/desktop/cxmp_pictograms.webp";
import ColorsFashion from "assets/images/comexposium/desktop/cxmp_colors/cxmp_colors_fashion.webp";
import ColorsFood from "assets/images/comexposium/desktop/cxmp_colors/cxmp_colors_food.webp";
import ColorsSport from "assets/images/comexposium/desktop/cxmp_colors/cxmp_colors_sport.webp";
import BannerImage from "../../shared/BannerImage";
import LogoSlider1 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_1.webp";
import LogoSlider2 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_2.webp";
import LogoSlider3 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_3.webp";
import LogoSlider4 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_4.webp";
import LogoSlider5 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_5.webp";
import LogoSlider6 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_6.webp";
import LogoSlider7 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_7.webp";
import LogoSlider8 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_8.webp";
import LogoSlider9 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_9.webp";
import LogoSlider10 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_10.webp";
import LogoSlider11 from "assets/images/comexposium/desktop/cxmp_logo_slider/cxmp_logo_slider_11.webp";

// Images for mobile device
import CxmpMoodboardMobile from "assets/images/comexposium/mobile/cxmp_moodboard.webp";
import GraphicBoardMobile from "assets/images/comexposium/mobile/cxmp_graphicboard.webp";
import LogoBannerMobile from "assets/images/comexposium/mobile/banner_cxmp_logo.webp";
import PosterBannerMobile from "assets/images/comexposium/mobile/banner_cxmp_poster.webp";
import LogoHorizontalMobile from "assets/images/comexposium/mobile/cxmp_logo_horizontal.webp";
import LogoMockupIphoneMobile from "assets/images/comexposium/mobile/cxmp_logo_mockup_iphone.webp";
import LogoSafeZoneMobile from "assets/images/comexposium/mobile/cxmp_logo_safe_zone.webp";
import LogoAppMobile from "assets/images/comexposium/mobile/cxmp_logo_app.webp";
import LogoAppMockupMobile from "assets/images/comexposium/mobile/cxmp_logo_app_mockup.webp";
import MockupPosterMobile from "assets/images/comexposium/mobile/cxmp_mockup_poster.webp";
import ImageSlicesMobile from "assets/images/comexposium/mobile/cxmp_image_slices.webp";
import MockupPlanningMobile from "assets/images/comexposium/mobile/cxmp_mockup_planning.webp";
import MockupLeafletMobile from "assets/images/comexposium/mobile/cxmp_mockup_leaflet.webp";
import MockupGuidesMobile from "assets/images/comexposium/mobile/cxmp_mockup_guides.webp";
import FontPoppinsMobile from "assets/images/comexposium/mobile/cxmp_font_poppins.webp";
import IllustrationsMobile from "assets/images/comexposium/mobile/cxmp_illustrations.webp";
import PosterFashionMobile from "assets/images/comexposium/mobile/cxmp_poster/cxmp_poster_fashion.webp";
import PosterFoodMobile from "assets/images/comexposium/mobile/cxmp_poster/cxmp_poster_food.webp";
import PosterSportMobile from "assets/images/comexposium/mobile/cxmp_poster/cxmp_poster_sport.webp";
import PictogramsMobile from "assets/images/comexposium/mobile/cxmp_pictograms.webp";
import ColorsFashionMobile from "assets/images/comexposium/mobile/cxmp_colors/cxmp_colors_fashion.webp";
import ColorsFoodMobile from "assets/images/comexposium/mobile/cxmp_colors/cxmp_colors_food.webp";
import ColorsSportMobile from "assets/images/comexposium/mobile/cxmp_colors/cxmp_colors_sport.webp";
import LogoSlider1Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_1.webp";
import LogoSlider2Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_2.webp";
import LogoSlider3Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_3.webp";
import LogoSlider4Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_4.webp";
import LogoSlider5Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_5.webp";
import LogoSlider6Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_6.webp";
import LogoSlider7Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_7.webp";
import LogoSlider8Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_8.webp";
import LogoSlider9Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_9.webp";
import LogoSlider10Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_10.webp";
import LogoSlider11Mobile from "assets/images/comexposium/mobile/cxmp_logo_slider/cxmp_logo_slider_11.webp";


const CxmpBranding = () => {
    const logoSliderImages = [
        {
            src: LogoSlider1Mobile,
            srcSet: LogoSlider1
        },
        {
            src: LogoSlider2Mobile,
            srcSet: LogoSlider2
        },
        {
            src: LogoSlider3Mobile,
            srcSet: LogoSlider3
        },
        {
            src: LogoSlider4Mobile,
            srcSet: LogoSlider4
        },
        {
            src: LogoSlider5Mobile,
            srcSet: LogoSlider5
        },
        {
            src: LogoSlider6Mobile,
            srcSet: LogoSlider6
        },
        {
            src: LogoSlider7Mobile,
            srcSet: LogoSlider7
        },
        {
            src: LogoSlider8Mobile,
            srcSet: LogoSlider8
        },
        {
            src: LogoSlider9Mobile,
            srcSet: LogoSlider9
        },
        {
            src: LogoSlider10Mobile,
            srcSet: LogoSlider10
        },
        {
            src: LogoSlider11Mobile,
            srcSet: LogoSlider11
        },
    ];
    return (
        <div className="branding">
            <section className="graphic-axis">
                {/*BRANDING SECTION*/}
                <div>
                    <h2 className="section-title">Un branding<br/>volontairement sobre</h2>
                    {/*Axe graphique*/}
                    <div className="two-pgph-block">
                        <h3>Axe graphique</h3>
                        <div className="two-paragraph-grid">
                            <p>
                                CXMP a à coeur de promouvoir des vendeurs,
                                des produits et des événements d’univers très variés.
                                La plateforme devait donc pouvoir rassembler toute cette diversité,
                                tout en laissant la place nécessaire aux univers pour s‘exprimer.
                            </p>
                            <p>
                                C’est cette idée qui a orientée la direction artistique du projet
                                vers la sobriété et la simplicité. Mais Comexposium,
                                c’est aussi 100 ans de savoir-faire. Fiabilité et élégance
                                devaient donc également transparaitre.
                            </p>
                        </div>
                    </div>
                    <ImageWithCaption src={CxmpMoodboardMobile} srcSet={CxmpMoodboard} caption="Moodboard"></ImageWithCaption>
                    <ImageWithCaption src={GraphicBoardMobile} srcSet={GraphicBoard} caption="Planche graphique"></ImageWithCaption>
                </div>
            </section>
            {/*Banner */}
            <BannerImage desktopImg={LogoBanner} mobileImg={LogoBannerMobile} altLabel="Logo CXMP"></BannerImage>
            <section className="logotype-sct">
                {/*Logotype */}
                <div className="two-pgph-block">
                    <h3>Logotype</h3>
                    <div className="two-paragraph-grid">
                        <p>
                            L’idée première pour le logotype a été de s’inspirer de celui de Comexposium
                            avec son X stylisé, dans un esprit de continuité. Certaines pistes évoquaient
                            fiabilité et sérieux, d’autres inspiraient originalité ou diversité.
                        </p>
                        <p>
                            La piste retenue a été choisie pour son minimalisme, sa prestance et son modernisme.
                            La simplicité de ce logo typographique lui permet d’avoir une variante compacte tout aussi efficace.
                        </p>
                    </div>
                </div>
                <div className="pinterest-grid logotype-pinterest-grid">
                        <div className="col-1">
                            <div className="image-wrapper">
                                <Image src={LogoHorizontalMobile} srcSet={LogoHorizontal} alt="Logo CXMP horizontal"/>
                            </div>
                            <div className="image-wrapper">
                                <Image src={LogoMockupIphoneMobile} srcSet={LogoMockupIphone} alt="Mockup iPhone"/>
                            </div>
                            <div className="image-wrapper">
                                <Image src={LogoSafeZoneMobile} srcSet={LogoSafeZone} alt="Zone de sécurité du logo"/>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="image-wrapper">
                                <Image src={LogoAppMobile} srcSet={LogoApp} alt="Logo application CXMP"/>
                            </div>
                            <div className="image-wrapper">
                                <Image src={LogoAppMockupMobile} srcSet={LogoAppMockup} alt="Mockup application"/>
                            </div>
                        </div>
                    </div>
            </section>
            <div className="banner-slider-ctnr">
                <swiper-container className="swiper logo-show-swiper"
                                  slides-per-view="1"
                                  space-between="0"
                                  autoplay-delay="1000"
                                  loop={true}>
                    {logoSliderImages.map((item, index) => {
                        return (
                            <swiper-slide key={index} className="swiper-slide">
                                <Image src={item.src} srcSet={item.srcSet} alt=""/>
                            </swiper-slide>)
                    })
                    }
                </swiper-container>
            </div>
            <section className="fonts-colors-sct">
                <div className="title-pgph-sct">
                    <h3>S’effacer pour laisser la<br/>diversité s’exprimer</h3>
                    <p>
                        Toujours dans cet objectif de sobriété, le choix de la police d’écriture
                        s’est tourné vers la simplicité et l’élégance, tandis que le choix des couleurs
                        s’est arrêté sur les très basiques mais toujours efficaces blanc, noir et nuances de gris.
                    </p>
                </div>
                {/*Fonts & Colors */}
                <div className="pinterest-grid fonts-colors-pinterest-grid">
                    <div className="col-1">
                        <figure>
                            <div className="image-wrapper">
                                <div>
                                    <Image src={FontPoppinsMobile} srcSet={FontPoppins} alt="Font Poppins"/>
                                </div>
                            </div>
                            <figcaption>
                                <span>Une police d’écriture<br/>épurée et populaire pour un<br/>branding qui rassemble</span>
                            </figcaption>
                        </figure>
                        <div className="image-wrapper">
                            <Image src={IllustrationsMobile} srcSet={Illustrations} alt="Illustrations"/>
                        </div>
                        <div className="slider-ctnr">
                            <swiper-container className="swiper"
                                              slides-per-view="1"
                                              space-between="24"
                                              autoplay-delay="800"
                                              loop={true}>
                                <swiper-slide className="swiper-slide">
                                    <div className="image-wrapper">
                                        <Image src={PosterFashionMobile} srcSet={PosterFashion} alt="Poster fashion"/>
                                    </div>
                                </swiper-slide>
                                <swiper-slide className="swiper-slide">
                                    <div className="image-wrapper">
                                        <Image src={PosterFoodMobile} srcSet={PosterFood} alt="Poster food"/>
                                    </div>
                                </swiper-slide>
                                <swiper-slide className="swiper-slide">
                                    <div className="image-wrapper">
                                        <Image src={PosterSportMobile} srcSet={PosterSport} alt="Poster Sport"/>
                                    </div>
                                </swiper-slide>
                            </swiper-container>
                        </div>
                    </div>
                    <div className="col-2">
                        <figure>
                            <div className="image-wrapper">
                                <div>
                                    <Image src={PictogramsMobile} srcSet={Pictograms} alt="Pictogrammes"/>
                                </div>
                            </div>
                            <figcaption>
                                <span>Des tracés filaires fins et<br/>monochromes pour un rendu<br/>minimaliste élégant</span>
                            </figcaption>
                        </figure>
                        <figure>
                            <div className="slider-ctnr">
                                <swiper-container className="swiper"
                                                  slides-per-view="1"
                                                  space-between="24"
                                                  autoplay-delay="800"
                                                  loop={true}>
                                    <swiper-slide className="swiper-slide">
                                        <div className="image-wrapper">
                                            <Image src={ColorsFashionMobile} srcSet={ColorsFashion} alt="Couleur thème fashion"/>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide className="swiper-slide">
                                        <div className="image-wrapper">
                                            <Image src={ColorsFoodMobile} srcSet={ColorsFood} alt="Couleur thème food"/>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide className="swiper-slide">
                                        <div className="image-wrapper">
                                            <Image src={ColorsSportMobile} srcSet={ColorsSport} alt="Couleur thème sport"/>
                                        </div>
                                    </swiper-slide>
                                </swiper-container>
                            </div>
                            <figcaption>
                                <span>Une palette de couleurs<br/>pour chaque univers pour une<br/>identification rapide</span>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </section>
            {/*Banner */}
            <BannerImage desktopImg={PosterBanner} mobileImg={PosterBannerMobile} altLabel="Poster CXMP"/>
            <section className="border-b image-usage-sct">
                <div className="title-pgph-sct">
                    <h3>Se démarquer par une<br/>utilisation judicieuse de la<br/>photographie</h3>
                    <div>
                        <p>La richesse de CXMP réside dans sa grande variété de produits.
                        Il fallait donc trouver un moyen de les mettre en valeur dans un espace plus ou moins limité
                        en fonction des supports.</p>
                        <p>L’identité de CXMP ayant été sciemment orientée vers la sobriété,
                            c’est par l’image qu’il a fallu se démarquer en créant un élément de branding singulier et mémorable.</p>
                    </div>
                </div>
                {/*Use of images*/}
                <div className="pinterest-grid image-usage-pinterest-grid">
                    <div className="col-1">
                        <figure>
                            <div className="image-wrapper">
                                <Image src={MockupPosterMobile} srcSet={MockupPoster} alt="Poster mockup"/>
                            </div>
                            <figcaption>
                                <span>Des images au format très vertical pour promouvoir tous les univers dans un espace restreint</span>
                            </figcaption>
                        </figure>
                        <figure>
                            <div className="image-wrapper">
                                <Image src={ImageSlicesMobile} srcSet={ImageSlices} alt=""/>
                            </div>
                            <figcaption>
                                <span>Une utilisation de la photo qui est devenue l’élément fort du branding de CXMP</span>
                            </figcaption>
                        </figure>
                        <div className="image-wrapper">
                            <Image src={MockupPlanningMobile} srcSet={MockupPlanning} alt=""/>
                        </div>
                    </div>
                    <div className="col-2">
                        <figure>
                            <div className="image-wrapper">
                                <Image src={MockupLeafletMobile} srcSet={MockupLeaflet} alt=""/>
                            </div>
                            <figcaption>
                                <span>Des compositions qui apportent du dynamisme et de l’humain sur tous les supports physiques et digitaux</span>
                            </figcaption>
                        </figure>
                        <div className="image-wrapper">
                            <Image src={MockupGuidesMobile} srcSet={MockupGuides} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CxmpBranding;