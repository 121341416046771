import React, {useState} from 'react';
import copy from 'copy-to-clipboard';

const Contact = () => {
    const [addingMailToClipBoard, setAddingMailToClipBoard] = useState(false)
    const copyMail = async () => {
        copy('mario.lemr@gmail.com');
        setAddingMailToClipBoard(true);
        setTimeout(() => {
            setAddingMailToClipBoard(false);
        }, 5000);
    };
    return (
        <section className="contact">
            <span id="contact" className="anchor"></span>
            <div className="contact-catch-phrase">
                <div className="contact-animated-circle"></div>
                <div className="contact-catch-phrase__text">
                    <span>Décrivez</span>
                    <span>moi votre</span>
                    <span className="editorial">formidable</span>
                    <span>projet</span>
                </div>
            </div>
            <div className="email-contact">
                <p>
                    <span>Travaillons ensemble,</span>
                    <span>écrivez-moi&nbsp;:</span>
                </p>
                <button className={"contact-btn " + (addingMailToClipBoard ? "clicked": "")} onClick={copyMail}>
                    <span className="contact-btn__text-ctnr">
                        <span className="contact-btn__text---email">mario.lemr@gmail.com</span>
                        <span className="contact-btn__text--copied">Copié !</span>
                    </span>
                </button>
            </div>
            <div className="social-network-ctnr">
                <div className="social-network-contact">
                    <p className="contact-type">DM</p>
                    <div className="social-network__links">
                        <a href="https://www.linkedin.com/in/mario-lemaire/" className="link" target="_blank" rel="noreferrer"><span
                            className="link-underline">LinkedIn</span></a>
                        {/*<a href="/" className="link" target="_blank" rel="noreferrer"><span className="link-underline">Instagram</span></a>*/}
                    </div>
                </div>
            </div>
            <div className="social-network-ctnr resume-link">
                <div className="social-network-contact">
                    <p className="contact-type">CV</p>
                    <div className="social-network__links">
                        <a href="assets/files/cv_mario_lemaire_dark.pdf" target="_blank" rel="noreferrer" className="link">
                            <span className="link-underline">CV Digital</span>
                        </a>
                        <a href="assets/files/cv_mario_lemaire_print.pdf" target="_blank" rel="noreferrer" className="link">
                            <span className="link-underline">CV Print</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;