import React from 'react';
import {NavLink} from "react-router-dom";
import Image from "../../components/shared/Image";
import useScrollToTop from "../../functions/hooks";
// Styles
import 'styles/views/comesxposium.scss';
// Components
import Cursor from "../../components/shared/Cursor";
import CxmpBranding from "components/projects/cxmp/CxmpBranding";
import ImageWithCaption from "../../components/projects/ImageWithCaption";
import BannerImage from "../../components/shared/BannerImage";
// Images
import Logo from 'assets/logo/logo_black.svg';
import CloseIcon from "assets/icons/close.svg";
// Images for desktop
import CxmpBanner from "assets/images/comexposium/desktop/banner_main_cxmp.webp";
import CxmpHomepage from "assets/images/comexposium/desktop/cxmp_homepage.webp";
import CxmpHomepageFashion from "assets/images/comexposium/desktop/cxmp_homepage_universes/cxmp_homepage_fashion.webp";
import CxmpHomepageFood from "assets/images/comexposium/desktop/cxmp_homepage_universes/cxmp_homepage_food.webp";
import CxmpHomepageSport from "assets/images/comexposium/desktop/cxmp_homepage_universes/cxmp_homepage_sport.webp";
import DesignSystem from "assets/images/comexposium/desktop/cxmp_design_system.webp";
import MobileScreensBanner from "assets/images/comexposium/desktop/banner_cxmp_mobile_screens.webp";
import DesktopScreens from "assets/images/comexposium/desktop/cxmp_desktop_screens.webp";
import MobileScreens from "assets/images/comexposium/desktop/cxmp_mobile_screens.webp";
import ProductPage from "assets/images/comexposium/desktop/cxmp_product_page.webp";
import SellerPage from "assets/images/comexposium/desktop/cxmp_seller_page.webp";
// Images for mobile device
import CxmpBannerMobile from "assets/images/comexposium/mobile/banner_main_cxmp.webp";
import CxmpHomepageMobile from "assets/images/comexposium/mobile/cxmp_homepage.webp";
import CxmpHomepageFashionMobile from "assets/images/comexposium/mobile/cxmp_homepage_universes/cxmp_homepage_fashion.webp";
import CxmpHomepageFoodMobile from "assets/images/comexposium/mobile/cxmp_homepage_universes/cxmp_homepage_food.webp";
import CxmpHomepageSportMobile from "assets/images/comexposium/mobile/cxmp_homepage_universes/cxmp_homepage_sport.webp";
import DesignSystemMobile from "assets/images/comexposium/mobile/cxmp_design_system.webp";
import MobileScreensBannerMobile from "assets/images/comexposium/mobile/banner_cxmp_mobile_screens.webp";
import DesktopScreensMobile from "assets/images/comexposium/mobile/cxmp_desktop_screens.webp";
import MobileScreensMobile from "assets/images/comexposium/mobile/cxmp_mobile_screens.webp";
import ProductPageMobile from "assets/images/comexposium/mobile/cxmp_product_page.webp";
import SellerPageMobile from "assets/images/comexposium/mobile/cxmp_seller_page.webp";
// Video
import CXMPHomepageAnimationWebm from "assets/video/cxmp/webm/cxmp_homepage_animation.webm";
import CXMPHomepageAnimationMp4 from "assets/video/cxmp/cxmp_homepage_animation.mp4";
import Contact from "../../components/home/Contact/Contact";



const Comexposium = () => {
    useScrollToTop();
    const universHomepages = [
        {
            srcSet: CxmpHomepageFashion,
            src: CxmpHomepageFashionMobile,
            altLabel: 'Page d\'accueil Fashion',
        },
        {
            srcSet: CxmpHomepageFood,
            src: CxmpHomepageFoodMobile,
            altLabel: 'Page d\'accueil Food',
        },
        {
            srcSet: CxmpHomepageSport,
            src: CxmpHomepageSportMobile,
            altLabel: 'Page d\'accueil Sport',
        },
    ];
    return (
        <div className="project-page cxmp-project">
            <Cursor/>
            <NavLink to="/#projects" className="go-to-home-btn">
                <img src={CloseIcon} alt="Fermer"/>
            </NavLink>
            <header className="header">
                <div className="logo">
                    <NavLink
                        to="/"
                        className="link"
                    >
                        <img src={Logo} alt="Mario Lemaire"/>
                    </NavLink>
                </div>
            </header>
            {/* HEADINGS SECTION */}
            <div className="headings">
                <p className="headings__client-name">Comexposium</p>
                <h1><span>La marketplace</span><span>qui <span className="editorial">rassemble</span></span></h1>
            </div>
            {/* Banner */}
            <BannerImage desktopImg={CxmpBanner} mobileImg={CxmpBannerMobile} altLabel="Bannière présentation CXMP"/>
            {/*CONTEXT SECTION*/}
            <section className="context border-b">
                <div className="context__content">
                    <h3>Le contexte</h3>
                    <p>
                    <span>
                        Comexposium organise plus de 135 événements professionnels
                        et grand public dans le monde entier en couvrant des secteurs
                        d’activité très variés comme la mode, le sport, l'agroalimentaire,
                        les loisirs, le tourisme, et plus encore.
                    </span>
                        <span>
                        Pour prolonger l’expérience en dehors des évènements physiques,
                        Comexposium a lancé CXMP : la marketplace qui rassemble exposants
                        et visiteurs pour leur permettre de continuer à échanger toute l’année.
                    </span>
                    </p>
                </div>
            </section>
            <CxmpBranding/>
            {/*CATEGORY SECTION*/}
            <section className="">
                <h2 className="section-title">Une marketplace<br/>salvatrice</h2>
                <div className="two-pgph-block">
                    <h3>Brief</h3>
                    <div className="two-paragraph-grid">
                        <p>
                            CXMP étant la pièce maitresse de la stratégie omnicanale de Comexposium,
                            la marketplace devait avoir un double emploi. Elle devait avant tout permettre
                            aux vendeurs de commercer toute l’année, en dehors des salons physiques.
                        </p>
                        <p>
                            Mais elle avait également pour mission de fédérer une communauté et de communiquer
                            sur les nombreux événements du groupe.  CXMP devait être autant une marketplace B2B
                            qu’un espace d’information et de partage.
                        </p>
                    </div>
                </div>
            </section>
            <div className="motion-video-wrapper video-wrapper homepage-motion">
                <video controls={false} autoPlay={true} loop playsInline={true}>
                    <source src={CXMPHomepageAnimationWebm} type="video/webm" />
                    <source src={CXMPHomepageAnimationMp4} type="video/mp4" />
                </video>
            </div>
            <section className="univers-homepage-sct">
                <div className="title-pgph-sct">
                    <h3>Un design simple et<br/>élégant dans lequel tout<br/>le monde se retrouve</h3>
                    <div>
                        <p>
                            Les écrans du site ont été pensés dans une logique Desktop First inhérente au secteur B2B.
                        </p>
                        <p>
                            Mon travail s’est concentré à la fois sur le front office et le middle office de la marketplace,
                            en composant avec les limites techniques de la solution retenue par le client.
                        </p>
                    </div>
                </div>
                <ImageWithCaption caption="Page d'accueil" src={CxmpHomepageMobile} srcSet={CxmpHomepage}></ImageWithCaption>
                <div className="image-container">
                    <div className="banner-slider-ctnr">
                        <swiper-container className="swiper logo-show-swiper"
                                          slides-per-view={1}
                                          space-between={0}
                                          autoplay-delay={800}
                                          loop={true}>
                            {universHomepages.map((item, index) => {
                                return (
                                    <swiper-slide key={index} className="swiper-slide">
                                        <Image src={item.src} srcSet={item.srcSet} alt={item.altLabel}/>
                                    </swiper-slide>)
                            })
                            }
                        </swiper-container>
                    </div>
                    <span className="image-container__caption">Page univers</span>
                </div>
            </section>
            {/* Banner */}
            <BannerImage desktopImg={MobileScreensBanner} mobileImg={MobileScreensBannerMobile} altLabel="Écrans mobiles"/>
            <section className="screens-show-sct">
                <h2 className="section-title">Une plateforme pour les rassembler tous</h2>
                <div className="pinterest-grid">
                    <div className="col-1">
                        <div className="image-wrapper">
                            <Image src={DesktopScreensMobile} srcSet={DesktopScreens} alt="Écrans desktop"/>
                        </div>
                        <div className="image-wrapper">
                            <Image src={ProductPageMobile} srcSet={ProductPage} alt="Page produit"/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="image-wrapper">
                            <Image src={MobileScreensMobile} srcSet={MobileScreens} alt="Écrans mobiles"/>
                        </div>
                        <div className="image-wrapper">
                            <Image src={SellerPageMobile} srcSet={SellerPage} alt="Page vendeur"/>
                        </div>

                    </div>
                </div>
                <div className="title-pgph-sct">
                    <h3>Un Design System<br/>ultra complet</h3>
                    <p>
                        Le Design System a été créé sur Figma en respectant l’axe graphique du branding.
                        Il est depuis régulièrement enrichi par la création de nouveaux composants,
                        et mis à jour avec les dernières fonctionnalités du logiciel.
                    </p>
                </div>
                <ImageWithCaption caption="Design system Figma" src={DesignSystemMobile} srcSet={DesignSystem}></ImageWithCaption>
            </section>
            <Contact/>
        </div>
    );
};

export default Comexposium;