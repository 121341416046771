import React from 'react';
import {Parallax, ParallaxBanner} from "react-scroll-parallax";
import './aboutBrands.scss';
import BrandsBannerDesktop from 'assets/svg/companies_logo_desktop.svg';
import BrandsBannerMobile from 'assets/svg//companies_logo_mobile.svg';


const AboutBrands = () => {
    return (
        <section className="about-brands">
            <div className="about-brands__text">
                <h2>
                    <span>On a <em className="editorial">télé-</em></span><span>travaillé&nbsp;</span>
                    <span>ensemble</span>
                </h2>
                <div className="about-brands__text--description">
                    <p>
                        Depuis 2014, je collabore avec de grandes marques et
                        d’innovantes startups sur des projets passionnants et
                        très variées pour tous types de secteurs d’activité.
                    </p>
                    <p>
                        Leaders de leurs secteurs ou bien nouvellement créées,
                        je mets toujours mon expertise et ma rigueur au service
                        des entreprises que j’accompagne.
                    </p>
                </div>
            </div>
            <ParallaxBanner>
                <Parallax
                    translateX={['40', '0']}
                    translateY={['0', '0']}
                    easing={'easeOut'}
                >
                    <div className="about-brands__grid-ctnr">
                        <div className="about-brands__logo-grid">
                            <picture>
                                <source srcSet={BrandsBannerDesktop} media="(min-width: 767px)"/>
                                <img src={BrandsBannerMobile} alt="Logos clients"/>
                            </picture>
                            <picture>
                                <source srcSet={BrandsBannerDesktop} media="(min-width: 767px)"/>
                                <img src={BrandsBannerMobile} alt="Logos clients"/>
                            </picture>
                        </div>
                    </div>
                </Parallax>
            </ParallaxBanner>
        </section>
    );
};

export default AboutBrands;