import React from 'react';
import ProjectCard from "components/home/ProjectCard/ProjectCard";
import projectsData from 'data/projects.json';
import './projectsSection.scss';

const ProjectsSection = () => {
    return (
        <section className="projects">
            <span id="projects" className="anchor"></span>
            <h2>Projets récents</h2>
            <ul className="projects-list picture-project">
                {projectsData.map(item => {
                    return (
                        <li key={item.id}>
                            <ProjectCard project={item}></ProjectCard>
                        </li>
                    );
                })}
            </ul>
            <div className="projects-hover-reveal" id="projects-hover-reveal">
                <ul className="projects-list-image">
                    {projectsData.filter(project => project.active).map(item => {
                        return (
                            <li key={item.id} className="projects-list-image__inner">>
                                <img src={item.image.url} alt={item.image.altLabel}/>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};

export default ProjectsSection;