import React from 'react';
import {NavLink} from "react-router-dom";
import 'styles/views/explomaker.scss';
// Components
import Cursor from "components/shared/Cursor";
import ExploBranding from "components/projects/explomaker/ExploBranding";
import ImageWithCaption from "components/projects/ImageWithCaption";
import BannerImage from "components/shared/BannerImage";
import ExploUserExperience from "components/projects/explomaker/ExploUserExperience";
import Image from "../../components/shared/Image";
import useScrollToTop from "../../functions/hooks";
// Images
import CloseIcon from "assets/icons/close.svg";
import Logo from "assets/logo/logo_black.svg";
// Images for desktop
import ExploBanner from "assets/images/explomaker/desktop/explomaker_banner_main.webp";
import ExploHomepage from "assets/images/explomaker/desktop/explomaker_homepage.webp";
import ExploArticle from "../../assets/images/explomaker/desktop/explomaker_screens_article.webp";
import ExploPage1 from "../../assets/images/explomaker/desktop/explomaker_screens_page_1.webp";
import ExploPage2 from "../../assets/images/explomaker/desktop/explomaker_screens_page_2.webp";
import ExploScreenMobile from "../../assets/images/explomaker/desktop/explomaker_screens_mobile.webp";
import ExploBannerMobileScreens from "../../assets/images/explomaker/desktop/explomaker_banner_mobile_screens.webp";
// Images for mobile device
import ExploBannerMobile from "assets/images/explomaker/mobile/explomaker_banner_main.webp";
import ExploHomepageMobile from "assets/images/explomaker/mobile/explomaker_homepage.webp";
import ExploArticleMobile from "../../assets/images/explomaker/mobile/explomaker_screens_article.webp";
import ExploPage1Mobile from "../../assets/images/explomaker/mobile/explomaker_screens_page_1.webp";
import ExploPage2Mobile from "../../assets/images/explomaker/mobile/explomaker_screens_page_2.webp";
import ExploScreenMobileMobile from "../../assets/images/explomaker/mobile/explomaker_screens_mobile.webp";
import ExploBannerMobileScreensMobile from "../../assets/images/explomaker/mobile/explomaker_banner_mobile_screens.webp";
//Video
import HomepageShow from "assets/video/explomaker/webm/presentation_hp_short.webm";
import Contact from "../../components/home/Contact/Contact";



const Explomaker = () => {
    useScrollToTop();
    return (
        <div id="explomaker" className="project-page explomaker-project">
            <Cursor/>
            <NavLink
                to="/#projects"
                className="go-to-home-btn"
            >
                <img src={CloseIcon} alt="Fermer"/>
            </NavLink>
            <header className="header">
                <div className="logo">
                    <NavLink
                        to="/"
                        className="link"
                    >
                        <img src={Logo} alt="Mario Lemaire"/>
                    </NavLink>
                </div>
            </header>
            {/* HEADINGS SECTION */}
            <div className="headings">
                <p className="headings__client-name">Explomaker</p>
                <h1><span>L’app qui vous</span><span>fait <span className="editorial">voyager</span></span></h1>
            </div>
            {/* Banner */}
            <BannerImage desktopImg={ExploBanner} mobileImg={ExploBannerMobile} altLabel="Bannière présentation Explomaker"></BannerImage>
            {/*CONTEXT SECTION*/}
            <section className="context border-b">
                <div className="context__content">
                    <h3>Le contexte</h3>
                    <p>
                    <span>
                        Explomaker se réinvente et a aujourd’hui pour ambition de révolutionner
                        la préparation de voyage en proposant un outil collaboratif très complet,
                        qui accompagne les voyageurs avant, pendant et après leur&nbsp;séjour.
                    </span>
                    </p>
                </div>
            </section>
            <ExploBranding/>
            <section>
                <h2 className="section-title">L’app qui donne<br/>envie de s’évader</h2>
                <div className="two-pgph-block">
                    <h3>Brief</h3>
                    <div className="two-paragraph-grid">
                        <p>
                            L’idée d’Explomaker est née d’un constat simple&nbsp;:
                            nous utilisons tous une dizaine d’applications mobile pour planifier
                            nos voyages, ce qui rend la tâche longue, complexe et fatigante.
                        </p>
                        <p>
                            L’objectif d’Explomaker&nbsp;: Rendre l’expérience de la préparation
                            de séjours efficiente et agréable en créant une application mobile
                            complète et collaborative.
                        </p>
                    </div>
                </div>
            </section>
            <div className="motion-video-wrapper video-wrapper homepage-motion">
                <video src={HomepageShow} autoPlay={true} loop playsInline={true}></video>
            </div>
            <section className="screens-show-sct">
                <div className="title-pgph-sct">
                    <h3>Présenter l’app et<br/>déployer la stratégie<br/>d’Inbound Marketing</h3>
                    <div>
                        <p>
                            Le site web a été pensé avant tout pour mettre en avant l’application,
                            présenter son concept et ses fonctionnalités innovantes.
                        </p>
                        <p>
                            Le deuxième objectif du site est de fournir de l’inspiration
                            et de l’information sur le thème du voyage pour augmenter le traffic,
                            et ainsi attirer, engager et fidéliser de nouveaux utilisateurs.
                        </p>
                    </div>
                </div>
                <ImageWithCaption caption="Page d'accueil Explomaker" src={ExploHomepageMobile} srcSet={ExploHomepage}></ImageWithCaption>
                <div className="pinterest-grid">
                    <div className="col-1">
                        <div className="image-wrapper">
                            <Image src={ExploArticleMobile} srcSet={ExploArticle} alt="Écrans d'article"/>
                        </div>
                        <div className="image-wrapper">
                            <Image src={ExploPage1Mobile} srcSet={ExploPage1} alt="Exemple de page"/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="image-wrapper">
                            <Image src={ExploScreenMobileMobile} srcSet={ExploScreenMobile} alt="Écrans mobiles"/>
                        </div>
                        <div className="image-wrapper">
                            <Image src={ExploPage2Mobile} srcSet={ExploPage2} alt="Exemple de page"/>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner */}
            <BannerImage desktopImg={ExploBannerMobileScreens} mobileImg={ExploBannerMobileScreensMobile} altLabel="Bannière écrans mobiles Explomaker"></BannerImage>
            <ExploUserExperience/>
            <Contact/>
        </div>
    );
};

export default Explomaker;