
import React, { useState, useEffect } from 'react';

const MenuItem = ({ itemName, anchorId, superScript }) => {
    const [anchorTarget, setAnchorTarget] = useState(null);

    useEffect(() => {
        setAnchorTarget(document.getElementById(anchorId));
    }, [anchorId]);

    const handleClick = event => {
        event.preventDefault();
        anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };


    return (
        <a href={`#${anchorId}`}
           onClick={handleClick}
           className="menu-link link"
           aria-label={`Scroll to ${itemName}`}>
            <span className="link-underline">
                {itemName}
                {superScript &&
                    <span className="super-script">04</span>}
            </span>
        </a>
    );
};

export default MenuItem;