import React from 'react';
import './header.scss';
import Logo from 'assets/logo/logo_white.svg';
import {NavLink} from "react-router-dom";
import MenuItem from "../MenuItem/MenuItem";

const Header = () => {
    return (
        <header className="header">
            <div className="logo">
                <NavLink
                    to="/"
                    className="link"
                >
                    <img src={Logo} alt=""/>
                </NavLink>
            </div>
            <div className="header__menu">
                <MenuItem itemName="À propos" anchorId="about"></MenuItem>
                <MenuItem itemName="Projets" anchorId="projects" superScript={true}></MenuItem>
                <MenuItem itemName="Contact" anchorId="contact"></MenuItem>
            </div>
        </header>
    );
};

export default Header;