import React from 'react';
import ImageWithCaption from "../ImageWithCaption";
import BannerImage from "../../shared/BannerImage";
import Image from "../../shared/Image";
// Images for desktop
import ExploMoodboard from "assets/images/explomaker/desktop/explomaker_moodboard.webp";
import ExploGraphicBoard from "assets/images/explomaker/desktop/explomaker_graphicboard.webp";
import ExploBannerLogo from "assets/images/explomaker/desktop/explomaker_banner_logo.webp";
import ExploLogo from "assets/images/explomaker/desktop/explomaker_logo.webp";
import ExploLogoConstruction from "assets/images/explomaker/desktop/explomaker_logo_construction.webp";
import ExploApp from "assets/images/explomaker/desktop/explomaker_logo_app.webp";
import ExploMobile from "assets/images/explomaker/desktop/explomaker_mobile.webp";
import ExploMockupsNotebook from "assets/images/explomaker/desktop/explomaker_mockups_notebook.webp";
import ExploMoockupsStickers from "assets/images/explomaker/desktop/explomaker_mockups_stickers.webp";
import ExploMockupsBillboard from "assets/images/explomaker/desktop/explomaker_mockups_billboard.webp";
import ExploMockupsTotebag from "assets/images/explomaker/desktop/explomaker_mockups_totebag.webp";
import ExploMockupsWaterbottle from "assets/images/explomaker/desktop/explomaker_mockups_waterbottle.webp";
import ExploMockupBannerStreet from "assets/images/explomaker/desktop/explomaker_banner_mockup_street.webp";
import ExploFontVesper from "assets/images/explomaker/desktop/explomaker_font_vesper.webp";
import ExploFontRubik from "assets/images/explomaker/desktop/explomaker_font_rubik.webp";
import ExploStyles from "assets/images/explomaker/desktop/explomaker_styles.webp";
import ExploPictos from "assets/images/explomaker/desktop/explomaker_pictograms.webp";
import ExploColorsBlue from "assets/images/explomaker/desktop/explomaker_colors_blue.webp";
import ExploColorsGreen from "assets/images/explomaker/desktop/explomaker_colors_green.webp";
import ExploColors from "assets/images/explomaker/desktop/explomaker_colors.webp";
import ExploColorsRed from "assets/images/explomaker/desktop/explomaker_colors_red.webp";
// Images for mobile device
import ExploMoodboardMobile from "assets/images/explomaker/mobile/explomaker_moodboard.webp";
import ExploGraphicBoardMobile from "assets/images/explomaker/mobile/explomaker_graphicboard.webp";
import ExploBannerLogoMobile from "assets/images/explomaker/mobile/explomaker_banner_logo.webp";
import ExploLogoMobile from "assets/images/explomaker/mobile/explomaker_logo.webp";
import ExploLogoConstructionMobile from "assets/images/explomaker/mobile/explomaker_logo_construction.webp";
import ExploAppMobile from "assets/images/explomaker/mobile/explomaker_logo_app.webp";
import ExploMobileMobile from "assets/images/explomaker/mobile/explomaker_mobile.webp";
import ExploMockupsNotebookMobile from "assets/images/explomaker/mobile/explomaker_mockups_notebook.webp";
import ExploMoockupsStickersMobile from "assets/images/explomaker/mobile/explomaker_mockups_stickers.webp";
import ExploMockupsBillboardMobile from "assets/images/explomaker/mobile/explomaker_mockups_billboard.webp";
import ExploMockupsTotebagMobile from "assets/images/explomaker/mobile/explomaker_mockups_totebag.webp";
import ExploMockupsWaterbottleMobile from "assets/images/explomaker/mobile/explomaker_mockups_waterbottle.webp";
import ExploMockupBannerStreetMobile from "assets/images/explomaker/mobile/explomaker_banner_mockup_street.webp";
import ExploFontVesperMobile from "assets/images/explomaker/mobile/explomaker_font_vesper.webp";
import ExploFontRubikMobile from "assets/images/explomaker/mobile/explomaker_font_rubik.webp";
import ExploStylesMobile from "assets/images/explomaker/mobile/explomaker_styles.webp";
import ExploPictosMobile from "assets/images/explomaker/mobile/explomaker_pictograms.webp";
import ExploColorsBlueMobile from "assets/images/explomaker/mobile/explomaker_colors_blue.webp";
import ExploColorsGreenMobile from "assets/images/explomaker/mobile/explomaker_colors_green.webp";
import ExploColorsMobile from "assets/images/explomaker/mobile/explomaker_colors.webp";
import ExploColorsRedMobile from "assets/images/explomaker/mobile/explomaker_colors_red.webp";
// Video
import Video from "assets/video/explomaker/webm/loop_animation.webm"
import LogoAnimation from "assets/video/explomaker/webm/explomaker_logo_anim.webm"


const ExploBranding = () => {
    return (
        <div className="branding">
            <section className="graphic-axis">
                {/*BRANDING SECTION*/}
                <div>
                    <h2 className="section-title">Un branding<br/>qui invite au voyage</h2>
                    {/*Axe graphique*/}
                    <div className="two-pgph-block">
                        <h3>Axe graphique</h3>
                        <div className="two-paragraph-grid">
                            <p>
                                Historiquement spécialisé dans les explorations sur-mesure,
                                conserver les notions de partage, de proximité,
                                voire d'amicalité de la relation client était primordial pour Explomaker.
                            </p>
                            <p>
                                Pour cibler un public jeune et dynamique, le branding
                                a été orienté vers quelque chose de ludique et fun, au ton informel,
                                invitant à l'évasion et à la découverte.
                            </p>
                        </div>
                    </div>
                    <ImageWithCaption src={ExploMoodboardMobile} srcSet={ExploMoodboard} caption="Moodboard"></ImageWithCaption>
                    <ImageWithCaption src={ExploGraphicBoardMobile} srcSet={ExploGraphicBoard} caption="Planche graphique"></ImageWithCaption>
                </div>
            </section>
            {/* Banner */}
            <BannerImage desktopImg={ExploBannerLogo} mobileImg={ExploBannerLogoMobile} altLabel="Bannière Logos Explomaker"/>
            <section className="logotype-sct">
                {/*Logotype */}
                <div className="two-pgph-block">
                    <h3>Logotype</h3>
                    <div className="two-paragraph-grid">
                        <p>
                            Les recherches se concentraient initialement sur un axe graphique artisanal,
                            “fait-main”, adapté à une petite entreprise de création de séjours sur-mesure.
                        </p>
                        <p>
                            La réorientation du projet nous a emmené vers des symboles minimalistes
                            correspondant bien mieux au nouveau projet d'app que l’entreprise souhaitait lancer.
                        </p>
                    </div>
                </div>
                <div className="logo-video-wrapper video-wrapper">
                    <video src={LogoAnimation} autoPlay={true} loop playsInline={true}></video>
                </div>
                <div className="pinterest-grid logotype-pinterest-grid">
                    <div className="col-1">
                        <div className="image-wrapper">
                            <Image src={ExploLogoMobile} srcSet={ExploLogo} alt="Logo Explomaker"/>
                        </div>
                        <div className="image-wrapper">
                            <video src={Video} autoPlay={true} loop playsInline={true}></video>
                        </div>
                        <div className="image-wrapper">
                            <Image src={ExploLogoConstructionMobile} srcSet={ExploLogoConstruction} alt="Traits de construction du logo"/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="image-wrapper">
                            <Image src={ExploAppMobile} srcSet={ExploApp} alt="Logo CXMP app"/>
                        </div>
                        <div className="image-wrapper">
                            <Image src={ExploMobileMobile} srcSet={ExploMobile} alt="Mockup app"/>
                        </div>
                    </div>
                </div>
                <div className="title-pgph-sct">
                    <h3>Le globe&nbsp;: symbole<br/>universel de l’exploration</h3>
                    <div>
                        <p>
                            Bien plus qu'un simple objet de décoration, le globe est le porteur de rêves,
                            d'histoires et de promesses d'aventures à venir.
                            Il incarne la quintessence de l'exploration humaine.
                        </p>
                        <p>
                            Voyageur intrépide ou rêveur contemplatif, le globe nous rappelle
                            l'infinie diversité des cultures, des paysages et des expériences
                            qui attendent ceux qui osent s'aventurer au-delà des horizons familiers.
                        </p>
                    </div>
                </div>
                <div className="pinterest-grid logotype-pinterest-grid">
                    <div className="col-1">
                        <div className="image-wrapper">
                            <Image src={ExploMockupsBillboardMobile} srcSet={ExploMockupsBillboard} alt="Mockup panneau d'affichage"/>
                        </div>
                        <div className="image-wrapper">
                            <Image src={ExploMockupsTotebagMobile} srcSet={ExploMockupsTotebag} alt="Mockup totebag"/>
                        </div>
                        <div className="image-wrapper">
                            <Image src={ExploMoockupsStickersMobile} srcSet={ExploMoockupsStickers} alt="Mockup stickers"/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="image-wrapper">
                            <Image src={ExploMockupsNotebookMobile} srcSet={ExploMockupsNotebook} alt="Mockup notebook"/>
                        </div>
                        <div className="image-wrapper">
                            <Image src={ExploMockupsWaterbottleMobile} srcSet={ExploMockupsWaterbottle} alt="Mockup gourde"/>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner */}
            <BannerImage desktopImg={ExploMockupBannerStreet} mobileImg={ExploMockupBannerStreetMobile} altLabel="Bannière Mockup Affichage Publicitaire"/>
            <section className="fonts-colors-sct border-b">
                <div className="title-pgph-sct">
                    <h3>Le voyage. Plus simple.<br/>Plus fun.</h3>
                    <div>
                        <p>
                            Les choix esthétiques sont pensés pour être en adéquation avec
                            la rafraîchissante expérience de voyage qu’Explomaker souhaite proposer.
                        </p>
                        <p>
                            Ainsi, l’univers graphique qui en découle est ludique et chaleureux,
                            il attise la curiosité et évoque la simplicité.
                        </p>
                    </div>
                </div>
                <div className="pinterest-grid">
                    <div className="col-1">
                        <figure>
                            <div className="image-wrapper">
                                <Image src={ExploFontVesperMobile} srcSet={ExploFontVesper} alt="Font Vesper"/>
                            </div>
                            <figcaption>
                                <span>
                                    Une police titre aux terminaisons calligraphiques&nbsp;:
                                    un clin d’oeil au passé “artisan du voyage” d’Explomaker</span>
                            </figcaption>
                        </figure>
                        <figure>
                            <div className="image-wrapper">
                                <Image src={ExploStylesMobile} srcSet={ExploStyles} alt="Exemple de design Explomaker"/>
                            </div>
                            <figcaption>
                                <span>
                                    L’utilisation d’émojis et l’emploi d’un ton informel pour apporter convivialité et sympathie
                                </span>
                            </figcaption>
                        </figure>
                        <figure>
                            <div className="slider-ctnr">
                                <swiper-container className="swiper"
                                                  slides-per-view="1"
                                                  space-between="24"
                                                  autoplay-delay="1500"
                                                  loop={true}>
                                    <swiper-slide className="swiper-slide">
                                        <div className="image-wrapper">
                                            <Image src={ExploColorsMobile} srcSet={ExploColors} alt="Utilisation des couleurs"/>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide className="swiper-slide">
                                        <div className="image-wrapper">
                                            <Image src={ExploColorsGreenMobile} srcSet={ExploColorsGreen} alt="Utilisation du Vert"/>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide className="swiper-slide">
                                        <div className="image-wrapper">
                                            <Image src={ExploColorsBlueMobile} srcSet={ExploColorsBlue} alt="Utilisation du Bleu"/>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide className="swiper-slide">
                                        <div className="image-wrapper">
                                            <Image src={ExploColorsRedMobile} srcSet={ExploColorsRed} alt="Utilisation du Rouge"/>
                                        </div>
                                    </swiper-slide>
                                </swiper-container>
                            </div>
                            <figcaption>
                                <span>
                                    Une palette inspirée par le monde et ses éléments, naturels comme urbains
                                </span>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-2">
                        <figure>
                            <div className="image-wrapper">
                                <Image src={ExploFontRubikMobile} srcSet={ExploFontRubik} alt="Font Rubik"/>
                            </div>
                            <figcaption>
                                <span>
                                    Une police claire et ludique pour un aspect décontracté et une lecture plaisante
                                </span>
                            </figcaption>
                        </figure>
                        <figure>
                            <div className="image-wrapper">
                                <Image src={ExploPictosMobile} srcSet={ExploPictos} alt="Pictogrammes Explomaker"/>
                            </div>
                            <figcaption>
                                <span>
                                    Des icônes pleines et arrondies pour apporter douceur et légèreté aux interfaces
                                </span>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ExploBranding;