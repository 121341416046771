import React, {useEffect} from 'react';
import Cursor from "../../components/shared/Cursor";
import Header from 'components/home/Header/Header';
import HeroSection from 'components/home/HeroSection/HeroSection';
import About from "components/home/About/About";
import Specialities from "components/home/Specialities/Specialities";
import AboutBrands from "components/home/AboutBrands/AboutBrands";
import ProjectsSection from "components/home/ProjectsSection/ProjectsSection";
import Contact from "components/home/Contact/Contact";
import Footer from "components/home/Footer/Footer";
import 'styles/views/home.scss';
import {useLocation} from "react-router-dom";


const Home = () => {
    const { hash } = useLocation();
    useEffect(() => {
        if(!!hash) {
            const anchorTarget = document.querySelector(hash);
            anchorTarget.scrollIntoView();
        }
    }, [hash]);

    return (
        <div>
            <Cursor/>
            <div className="homepage">
                <Header/>
                <HeroSection/>
                <div className="main-wrapper">
                    <section className="about">
                        <About/>
                        <Specialities/>
                    </section>
                    <AboutBrands/>
                    <ProjectsSection/>
                    <Contact/>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default Home;
