import React from 'react';
import Image from "../shared/Image";

const ImageWithCaption = ({src, srcSet, caption}) => {
    return (
        <div className="image-container">
            <div className="image-wrapper">
                <Image src={src} srcSet={srcSet} alt={caption}/>
            </div>
            {caption &&
                <span className="image-container__caption">{caption}</span>}
        </div>
    );
};

export default ImageWithCaption;