import React from 'react';
import {NavLink} from "react-router-dom";

const ProjectCard = (props) => {
    let project = props.project;
    return (
        <>
        {project.active &&
            <NavLink className="projects-list__item active"
               to={project.url}>
                <div className="projects-list__item--preview">
                    <img src={project.image.url} alt={project.image.altLabel}/>
                </div>
                <p className="projects-list__item--title">{project.title}</p>
                <p className="projects-list__item--sub">{project.services}</p>
            </NavLink>
        }
        {!project.active &&
            <div className="projects-list__item projects-list__item-coming-soon">
                {project.image.url && <div className="projects-list__item--preview">
                    <img src={project.image.url} alt={project.image.altLabel}/>
                </div>}
                <p className="projects-list__item--title">{project.title}</p>
                <p className="projects-list__item--tag">Prochainement</p>
            </div>
        }
        </>
    );
};

export default ProjectCard;