import React, {useEffect, useState} from 'react';
import './specialities.scss';
import ModalSpeciality from "components/modalSpecialities/ModalSpeciality";
import SpecialityCard from "./SpecialityCard";
import SpecialityData from 'data/specialities.json';


const Specialities = () => {
    const [showModal, setShowModal] = useState(false);
    const [specialityId, setSpecialityId] = useState(0);
    const openSpecialityModal = (id) => {
        setSpecialityId(id);
        setShowModal(true)
    }
    useEffect(() => {
        const bodyElmt = document.body;
        if(bodyElmt){
            bodyElmt.style.overflow = showModal ? "hidden" : "initial";
        }
    }, [showModal]);

    return (
        <>
            <div className="sub-section about-specialities">
                <h2>Mes spécialités</h2>
                <swiper-container className="swiper speciality-description"
                    slides-per-view='auto'
                    space-between="24"
                    pagination="true"
                    pagination-type="bullets"
                    breakpoints={
                      JSON.stringify({
                          1355: {
                              slidesPerView: 4,
                              spaceBetween: '2%'
                          }
                      })
                    }>
                    {SpecialityData.map((item, index) => {
                        return (
                            <swiper-slide key={item.id} class="swiper-slide">
                                <SpecialityCard speciality={item} openModal={() => openSpecialityModal(index)}></SpecialityCard>
                            </swiper-slide>)
                    })}
                </swiper-container>
            </div>
            {showModal && <ModalSpeciality id={specialityId} onClose={() => setShowModal(false)}></ModalSpeciality>}
        </>
    );
};

export default Specialities;