import './styles/app.scss';
import {
    createBrowserRouter, RouterProvider
} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ParallaxProvider} from "react-scroll-parallax";
import Home from "./views/Home/Home";
import Comexposium from "./views/projets/Comexposium";
import Explomaker from "./views/projets/Explomaker";
import LogoLottie from "assets/lotties/logo_loader.json";
import {Player} from "@lottiefiles/react-lottie-player";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/projets/comexposium',
        element: <Comexposium />,
    },
    {
        path: '/projets/explomaker',
        element: <Explomaker />,
    },
    // {
    //     path: '*',
    //     element: <NotFoundPage />,
    // },
]);

function App() {
    const [lottie, setLottie] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(lottie) {
            lottie.play();
        }
    }, [lottie]);
  return (
      <div className="App">
          {loading ?
              <div className="loader">
                  <Player
                      lottieRef={instance => {
                          setLottie(instance);
                      }}
                      onEvent={event => {
                          if (event === 'complete') setLoading(false); // check event type and do something
                      }}
                      className="loader-logo"
                      src={LogoLottie}
                  >
                  </Player>
              </div>
              : <ParallaxProvider>
                  <RouterProvider router={router}/>
              </ParallaxProvider>
          }
      </div>
  );
}

export default App;
