// Get mouse position
const mouse = { x: -100, y: -100 }; // mouse pointer's coordinates
const updateCoordinates = e => {
    mouse.x = e.clientX;
    mouse.y = e.clientY;
}
window.addEventListener('mousemove', updateCoordinates);

/**
 * class CursorFollow
 */
export class CursorFollow {
    el;
    pos = {x: 0, y: 0}; // cursor's coordinates
    speed = 0.1; // between 0 and 1
    requestAnimationId;

    constructor(elementId, speed = 0.1) {
        this.el = document.getElementById(elementId);
        this.speed = speed;
    }

    loop() {
        const diffX = Math.round(mouse.x - this.pos.x);
        const diffY = Math.round(mouse.y - this.pos.y);

        this.pos.x += diffX * this.speed;
        this.pos.y += diffY * this.speed;

        if(this.el) {
            this.el.style.transform = 'translate3d(' + this.pos.x + 'px ,' + this.pos.y + 'px, 0)';
            this.requestAnimationId = requestAnimationFrame(this.loop.bind(this));
        }
    }

    init() {
        this.requestAnimationId = requestAnimationFrame(this.loop.bind(this));
    }

    cancelAnimation() {
        cancelAnimationFrame(this.requestAnimationId);
    }
}

/**
 * Modify main cursor class while hovering some elements
 * @param elmtSelector
 * @param cursorClass
 */
export const modifyCursor = (elmtSelector, cursorClass) => {
    const cursorAnimated = document.querySelector('#cursor');
    const cursorModifiers = document.querySelectorAll(elmtSelector);

    if (cursorModifiers) {
        cursorModifiers.forEach(cursorModifier => {
            cursorModifier.addEventListener('mouseenter', function () {
                cursorAnimated.classList.add(cursorClass);
            });

            cursorModifier.addEventListener('mouseleave', function () {
                cursorAnimated.classList.remove(cursorClass);
            });
        });
    }
}
