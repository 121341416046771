import React from 'react';
import Logo from "assets/logo/logo_black.svg"

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="footer">
            <div className="footer__rights">
                <img src={Logo} alt="Mario Lemaire" className="logo"/>
                <div>
                    <p>Mario Lemaire</p>
                    <p>© {year} - Tous droits réservés</p>
                </div>
            </div>
            <div className="footer__creators">
                <div>
                    <p>Design</p>
                    <span className="footer__creators--name">Mario Lemaire</span>
                </div>
                <div>
                    <p>Développement</p>
                    <a href="https://www.linkedin.com/in/anne-sophie-jovinac/" className="link" target="_blank" rel="noreferrer">
                        <span className="link-underline footer__creators--name">Anne-Sophie Jovinac</span>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;