import React from 'react';
import Image from "components/shared/Image";
// Images for desktop
import ExploScreenAlbums from "assets/images/explomaker/desktop/explomaker_screens_albums.webp";
import ExploScreenCollab from "assets/images/explomaker/desktop/explomaker_screens_collab.webp";
import ExploScreenDesktop from "assets/images/explomaker/desktop/explomaker_screens_desktop.webp";
import ExploScreenLogin from "assets/images/explomaker/desktop/explomaker_screens_login.webp";
// Images for mobile device
import ExploScreenAlbumsMobile from "assets/images/explomaker/mobile/explomaker_screens_albums.webp";
import ExploScreenCollabMobile from "assets/images/explomaker/mobile/explomaker_screens_collab.webp";
import ExploScreenDesktopMobile from "assets/images/explomaker/mobile/explomaker_screens_desktop.webp";
import ExploScreenLoginMobile from "assets/images/explomaker/mobile/explomaker_screens_login.webp";
// Videos
import AppLaunch from "assets/video/explomaker/webm/app_launch_landscape.webm";
import TripCreation from "assets/video/explomaker/webm/crea_trip.webm";
import EventCreation from "assets/video/explomaker/webm/event_creator.webm";
import Inspiration from "assets/video/explomaker/webm/anim_inspi.webm";


const ExploUserExperience = () => {
    return (
        <section className="user-experience-sct">
            <div className="title-pgph-sct">
                <h3>Une app complète<br/>
                    pour un séjour sans<br/>
                    prise de tête</h3>
                <div>
                    <p>
                        Des Flow-Charts aux prototypes en passant par les User-Flows,
                        plus d’une dizaine de parcours ont été pensés et maquettés
                        pour offrir aux utilisateurs la meilleure expérience possible.
                    </p>
                </div>
            </div>
            <div className="app-launch-video-wrapper video-wrapper">
                <video src={AppLaunch} autoPlay={true} loop playsInline={true}></video>
            </div>
            <div className="title-pgph-sct">
                <h3>Des fonctionnalités<br/>pensées par et pour ses<br/>utilisateurs</h3>
                <div>
                    <p>
                        La création et l’édition d’un planning de voyage à plusieurs
                        est le coeur de l’app, sa promesse, son utilité globale.
                    </p>
                    <p>
                        Pour pousser la collaboration encore plus loin et rendre
                        l’app indispensable, des micro-utilités ont ensuite été pensées,
                        conçues puis intégrées&nbsp;:
                    </p>
                    <ul className="custom-list">
                        <li>Système de notification</li>
                        <li>Messagerie instantanée de groupe</li>
                        <li>Création de sondages</li>
                        <li>Partage des envies de chacun</li>
                        <li>Documents et notes partagées</li>
                        <li>Albums photos partagés</li>
                    </ul>
                </div>
            </div>
            <div className="pinterest-grid">
                <div className="col-1">
                    <figure>
                        <div className="video-wrapper">
                            <video src={TripCreation} autoPlay={true} loop playsInline={true}></video>
                        </div>
                        <figcaption>
                                <span>Création d’un séjour</span>
                        </figcaption>
                    </figure>
                    <figure>
                        <div className="image-wrapper">
                            <Image src={ExploScreenLoginMobile} srcSet={ExploScreenLogin} alt="Inscription et connexion"/>
                        </div>
                        <figcaption>
                            <span>Inscription et connexion</span>
                        </figcaption>
                    </figure>
                    <figure>
                        <div className="image-wrapper">
                            <Image src={ExploScreenAlbumsMobile} srcSet={ExploScreenAlbums} alt="Albums photos partagés"/>
                        </div>
                        <figcaption>
                            <span>Albums photos partagés</span>
                        </figcaption>
                    </figure>
                    <figure>
                        <div className="video-wrapper">
                            <video src={Inspiration} autoPlay={true} loop playsInline={true}></video>
                        </div>
                        <figcaption>
                            <span>Partie inspiration</span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-2">
                    <figure>
                        <div className="image-wrapper">
                            <Image src={ExploScreenCollabMobile} srcSet={ExploScreenCollab} alt="Fonctionnalités collaboratives"/>
                        </div>
                        <figcaption>
                            <span>Fonctionnalités collaboratives</span>
                        </figcaption>
                    </figure>
                    <figure>
                        <div className="video-wrapper">
                            <video src={EventCreation} autoPlay={true} loop playsInline={true}></video>
                        </div>
                        <figcaption>
                            <span>Édition du planning</span>
                        </figcaption>
                    </figure>
                    <figure>
                        <div className="image-wrapper">
                            <Image src={ExploScreenDesktopMobile} srcSet={ExploScreenDesktop} alt="Version desktop de l’app"/>
                        </div>
                        <figcaption>
                            <span>Version desktop de l’app</span>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </section>
    );
};

export default ExploUserExperience;